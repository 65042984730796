{
  "v": "5.6.5",
  "fr": 33.3333282470703,
  "ip": 0,
  "op": 113.001112768696,
  "w": 200,
  "h": 200,
  "nm": "Workout_Complete_Left_0",
  "ddd": 0,
  "assets": [
    {
      "id": "comp_0",
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          "nm": "Shape Layer 67",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 230, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 63.001,
                  "s": [138.2, 219.1, 0],
                  "to": [0.25, 5.083, 0],
                  "ti": [-0.25, -5.083, 0]
                },
                { "t": 67.0006597832092, "s": [139.7, 249.6, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [-128.7, 145.978, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 64.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 67.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [99.836, -66.419]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 70.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-61.96, 87.17]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [102.098, -98.71]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 81.0007976483573,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-60.508, 79.667]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [42.636, -85.529],
                              [113.078, -119.097]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.964705882353, 0.788235294118, 0.780392156863, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 64.001,
                    "s": [0]
                  },
                  { "t": 81.0007976483573, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 65.000640088188,
          "op": 81.0007976483573,
          "st": 25.0002461877646,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          "nm": "Shape Layer 66",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 167, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 63.001,
                  "s": [239, 258.7, 0],
                  "to": [0.25, 5.083, 0],
                  "ti": [-0.25, -5.083, 0]
                },
                { "t": 67.0006597832092, "s": [240.5, 289.2, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [128.7, 128.7, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [[0, 0]],
                      "o": [[0, 0]],
                      "v": [[127.622, -89.51]],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.964705882353, 0.788235294118, 0.780392156863, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 2",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 64.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 67.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [89.196, -75.245]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 70.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-72.97, 83.392]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [114.839, -100.679]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 81.0007976483573,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-54.394, 83.576]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [51.199, -76.956],
                              [135.11, -110.236]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.964705882353, 0.788235294118, 0.780392156863, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 64.001,
                    "s": [0]
                  },
                  { "t": 81.0007976483573, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 3,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 65.000640088188,
          "op": 81.0007976483573,
          "st": 25.0002461877646,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 4,
          "nm": "Shape Layer 65",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 73, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 63.001,
                  "s": [256.875, 273.451, 0],
                  "to": [11.667, -1.5, 0],
                  "ti": [-11.667, 1.5, 0]
                },
                { "t": 67.0006597832092, "s": [326.875, 264.451, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [96.416, -94.09, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 63.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [104.164, -184.283]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 80.0007878008468,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [106.262, -193.724]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.964705882353, 0.788235294118, 0.780392156863, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 66.001,
                    "s": [0]
                  },
                  { "t": 80.0007878008468, "s": [100] }
                ],
                "ix": 1
              },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 63.001,
                    "s": [23]
                  },
                  { "t": 72.0007090207621, "s": [100] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 65.000640088188,
          "op": 79.0007779533362,
          "st": 24.000236340254,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 4,
          "nm": "Shape Layer 64",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 154, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 63.001,
                  "s": [143.5, 300.5, 0],
                  "to": [5.417, 7.25, 0],
                  "ti": [-5.417, -7.25, 0]
                },
                { "t": 67.0006597832092, "s": [176, 344, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [-143, 162.197, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 64.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 67.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [99.836, -66.419]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 70.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [132.796, -135.728]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 81.0007976483573,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-60.508, 79.667]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.148, -139.708]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.964705882353, 0.788235294118, 0.780392156863, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 64.001,
                    "s": [0]
                  },
                  { "t": 81.0007976483573, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 65.000640088188,
          "op": 80.0007878008468,
          "st": 25.0002461877646,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 4,
          "nm": "Shape Layer 63",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 91, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 63.001,
                  "s": [218.5, 205.5, 0],
                  "to": [7.25, 4.583, 0],
                  "ti": [-7.25, -4.583, 0]
                },
                { "t": 67.0006597832092, "s": [262, 233, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [[0, 0]],
                      "o": [[0, 0]],
                      "v": [[127.622, -89.51]],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.964705882353, 0.788235294118, 0.780392156863, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 2",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 64.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 67.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [100.318, -64.703]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 70.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [135.983, -130.087]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 81.0007976483573,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-68.85, 104.213]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.276, -141.276]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.964705882353, 0.788235294118, 0.780392156863, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 64.001,
                    "s": [0]
                  },
                  { "t": 81.0007976483573, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 3,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 65.000640088188,
          "op": 80.0007878008468,
          "st": 25.0002461877646,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 4,
          "nm": "Shape Layer 62",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 290, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 63.001,
                  "s": [102.875, 280.951, 0],
                  "to": [-1.167, -0.667, 0],
                  "ti": [1.167, 0.667, 0]
                },
                { "t": 67.0006597832092, "s": [95.875, 276.951, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [96.416, -94.09, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 63.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [98.919, -132.099]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 80.0007878008468,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [98.548, -141.028]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.964705882353, 0.788235294118, 0.780392156863, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 66.001,
                    "s": [0]
                  },
                  { "t": 80.0007878008468, "s": [100] }
                ],
                "ix": 1
              },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 63.001,
                    "s": [23]
                  },
                  { "t": 72.0007090207621, "s": [100] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 65.000640088188,
          "op": 80.0007878008468,
          "st": 24.000236340254,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 4,
          "nm": "Shape Layer 61",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 158, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 63.001,
                  "s": [212.875, 323.951, 0],
                  "to": [3.5, 9.167, 0],
                  "ti": [-3.5, -9.167, 0]
                },
                { "t": 67.0006597832092, "s": [233.875, 378.951, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [96.416, -94.09, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 63.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [98.919, -132.099]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 80.0007878008468,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [98.548, -141.028]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.964705882353, 0.788235294118, 0.780392156863, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 66.001,
                    "s": [0]
                  },
                  { "t": 80.0007878008468, "s": [100] }
                ],
                "ix": 1
              },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 63.001,
                    "s": [23]
                  },
                  { "t": 72.0007090207621, "s": [100] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 65.000640088188,
          "op": 80.0007878008468,
          "st": 24.000236340254,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 8,
          "ty": 4,
          "nm": "Shape Layer 60",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -11, "ix": 10 },
            "p": { "a": 0, "k": [245.5, 222, 0], "ix": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [-143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 65.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 68.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [99.836, -66.419]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 70.528,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [132.796, -135.728]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 82.0008074958679,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-60.508, 79.667]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.148, -139.708]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.964705882353, 0.788235294118, 0.780392156863, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 65.001,
                    "s": [0]
                  },
                  { "t": 82.0008074958679, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 65.000640088188,
          "op": 81.0007976483573,
          "st": 26.0002560352752,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 9,
          "ty": 4,
          "nm": "Shape Layer 59",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -74, "ix": 10 },
            "p": { "a": 0, "k": [141.5, 315, 0], "ix": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [[0, 0]],
                      "o": [[0, 0]],
                      "v": [[127.622, -89.51]],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.964705882353, 0.788235294118, 0.780392156863, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 2",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 65.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 68.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [100.318, -64.703]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 70.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [135.983, -130.087]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 82.0008074958679,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-68.85, 104.213]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.276, -141.276]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.964705882353, 0.788235294118, 0.780392156863, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 65.001,
                    "s": [0]
                  },
                  { "t": 82.0008074958679, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 3,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 65.000640088188,
          "op": 81.0007976483573,
          "st": 26.0002560352752,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 10,
          "ty": 4,
          "nm": "Shape Layer 58",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 63.001,
                  "s": [191.875, 231.951, 0],
                  "to": [1.167, -10.167, 0],
                  "ti": [-1.167, 10.167, 0]
                },
                { "t": 67.0006597832092, "s": [198.875, 170.951, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [96.416, -94.09, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 63.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [104.164, -184.283]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 80.0007878008468,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [106.262, -193.724]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.964705882353, 0.788235294118, 0.780392156863, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 66.001,
                    "s": [0]
                  },
                  { "t": 80.0007878008468, "s": [100] }
                ],
                "ix": 1
              },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 63.001,
                    "s": [23]
                  },
                  { "t": 72.0007090207621, "s": [100] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 65.000640088188,
          "op": 79.0007779533362,
          "st": 24.000236340254,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 11,
          "ty": 4,
          "nm": "Shape Layer 57",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 63, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 63.001,
                  "s": [265.5, 332, 0],
                  "to": [5.5, -2.167, 0],
                  "ti": [-5.5, 2.167, 0]
                },
                { "t": 67.0006597832092, "s": [298.5, 319, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [-143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 64.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 67.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [99.836, -66.419]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 69.528,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [132.796, -135.728]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 81.0007976483573,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-60.508, 79.667]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.148, -139.708]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.964705882353, 0.788235294118, 0.780392156863, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 64.001,
                    "s": [0]
                  },
                  { "t": 81.0007976483573, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 65.000640088188,
          "op": 80.0007878008468,
          "st": 25.0002461877646,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 12,
          "ty": 4,
          "nm": "Shape Layer 56",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 0, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 63.001,
                  "s": [160.5, 266, 0],
                  "to": [2.333, -4.167, 0],
                  "ti": [-2.333, 4.167, 0]
                },
                { "t": 67.0006597832092, "s": [174.5, 241, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [[0, 0]],
                      "o": [[0, 0]],
                      "v": [[127.622, -89.51]],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.964705882353, 0.788235294118, 0.780392156863, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 2",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 64.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 67.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [100.318, -64.703]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 69.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [135.983, -130.087]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 81.0007976483573,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-68.85, 104.213]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.276, -141.276]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.964705882353, 0.788235294118, 0.780392156863, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 64.001,
                    "s": [0]
                  },
                  { "t": 81.0007976483573, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 3,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 65.000640088188,
          "op": 80.0007878008468,
          "st": 25.0002461877646,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 13,
          "ty": 4,
          "nm": "Shape Layer 43",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 140, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 92.001,
                  "s": [219.1, 341.8, 0],
                  "to": [5.083, -0.25, 0],
                  "ti": [-5.083, 0.25, 0]
                },
                { "t": 96.0009453610161, "s": [249.6, 340.3, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [-128.7, 145.978, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 93.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 96.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [99.836, -66.419]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 99.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-61.96, 87.17]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [102.098, -98.71]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 110.001083226164,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-60.508, 79.667]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [42.636, -85.529],
                              [113.078, -119.097]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0, 0.811764705882, 0.854901960784, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 93.001,
                    "s": [0]
                  },
                  { "t": 110.001083226164, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 94.0009256659949,
          "op": 111.001093073675,
          "st": 54.0005317655716,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 14,
          "ty": 4,
          "nm": "Shape Layer 42",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 77, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 92.001,
                  "s": [258.7, 241, 0],
                  "to": [5.083, -0.25, 0],
                  "ti": [-5.083, 0.25, 0]
                },
                { "t": 96.0009453610161, "s": [289.2, 239.5, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [128.7, 128.7, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [[0, 0]],
                      "o": [[0, 0]],
                      "v": [[127.622, -89.51]],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0, 0.811764705882, 0.854901960784, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 2",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 93.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 96.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [89.196, -75.245]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 99.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-72.97, 83.392]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [114.839, -100.679]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 110.001083226164,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-54.394, 83.576]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [51.199, -76.956],
                              [135.11, -110.236]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0, 0.811764705882, 0.854901960784, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 93.001,
                    "s": [0]
                  },
                  { "t": 110.001083226164, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 3,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 94.0009256659949,
          "op": 111.001093073675,
          "st": 54.0005317655716,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 15,
          "ty": 4,
          "nm": "Shape Layer 41",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -17, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 92.001,
                  "s": [273.451, 223.125, 0],
                  "to": [-1.5, -11.667, 0],
                  "ti": [1.5, 11.667, 0]
                },
                { "t": 96.0009453610161, "s": [264.451, 153.125, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [96.416, -94.09, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 92.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [104.164, -184.283]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 109.001073378654,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [106.262, -193.724]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0, 0.811764705882, 0.854901960784, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 95.001,
                    "s": [0]
                  },
                  { "t": 109.001073378654, "s": [100] }
                ],
                "ix": 1
              },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 92.001,
                    "s": [23]
                  },
                  { "t": 101.000994598569, "s": [100] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 94.0009256659949,
          "op": 109.001073378654,
          "st": 53.000521918061,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 16,
          "ty": 4,
          "nm": "Shape Layer 40",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 64, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 92.001,
                  "s": [300.5, 336.5, 0],
                  "to": [7.25, -5.417, 0],
                  "ti": [-7.25, 5.417, 0]
                },
                { "t": 96.0009453610161, "s": [344, 304, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [-143, 162.197, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 93.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 96.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [99.836, -66.419]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 99.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [132.796, -135.728]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 110.001083226164,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-60.508, 79.667]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.148, -139.708]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0, 0.811764705882, 0.854901960784, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 93.001,
                    "s": [0]
                  },
                  { "t": 110.001083226164, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 94.0009256659949,
          "op": 110.001083226164,
          "st": 54.0005317655716,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 17,
          "ty": 4,
          "nm": "Shape Layer 39",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 1, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 92.001,
                  "s": [205.5, 261.5, 0],
                  "to": [4.583, -7.25, 0],
                  "ti": [-4.583, 7.25, 0]
                },
                { "t": 96.0009453610161, "s": [233, 218, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [[0, 0]],
                      "o": [[0, 0]],
                      "v": [[127.622, -89.51]],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0, 0.811764705882, 0.854901960784, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 2",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 93.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 96.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [100.318, -64.703]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 99.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [135.983, -130.087]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 110.001083226164,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-68.85, 104.213]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.276, -141.276]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0, 0.811764705882, 0.854901960784, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 93.001,
                    "s": [0]
                  },
                  { "t": 110.001083226164, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 3,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 94.0009256659949,
          "op": 110.001083226164,
          "st": 54.0005317655716,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 18,
          "ty": 4,
          "nm": "Shape Layer 38",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 200, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 92.001,
                  "s": [280.951, 377.125, 0],
                  "to": [-0.667, 1.167, 0],
                  "ti": [0.667, -1.167, 0]
                },
                { "t": 96.0009453610161, "s": [276.951, 384.125, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [96.416, -94.09, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 92.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [98.919, -132.099]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 109.001073378654,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [98.548, -141.028]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0, 0.811764705882, 0.854901960784, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 95.001,
                    "s": [0]
                  },
                  { "t": 109.001073378654, "s": [100] }
                ],
                "ix": 1
              },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 92.001,
                    "s": [23]
                  },
                  { "t": 101.000994598569, "s": [100] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 94.0009256659949,
          "op": 110.001083226164,
          "st": 53.000521918061,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 19,
          "ty": 4,
          "nm": "Shape Layer 37",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 68, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 92.001,
                  "s": [323.951, 267.125, 0],
                  "to": [9.167, -3.5, 0],
                  "ti": [-9.167, 3.5, 0]
                },
                { "t": 96.0009453610161, "s": [378.951, 246.125, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [96.416, -94.09, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 92.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [98.919, -132.099]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 109.001073378654,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [98.548, -141.028]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0, 0.811764705882, 0.854901960784, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 95.001,
                    "s": [0]
                  },
                  { "t": 109.001073378654, "s": [100] }
                ],
                "ix": 1
              },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 92.001,
                    "s": [23]
                  },
                  { "t": 101.000994598569, "s": [100] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 92.0009059709738,
          "op": 110.001083226164,
          "st": 53.000521918061,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 20,
          "ty": 4,
          "nm": "Shape Layer 36",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -101, "ix": 10 },
            "p": { "a": 0, "k": [222, 234.5, 0], "ix": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [-143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 94.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 97.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [99.836, -66.419]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 99.528,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [132.796, -135.728]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 111.001093073675,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-60.508, 79.667]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.148, -139.708]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0, 0.811764705882, 0.854901960784, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 94.001,
                    "s": [0]
                  },
                  { "t": 111.001093073675, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 92.0009059709738,
          "op": 111.001093073675,
          "st": 55.0005416130821,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 21,
          "ty": 4,
          "nm": "Shape Layer 35",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -164, "ix": 10 },
            "p": { "a": 0, "k": [315, 338.5, 0], "ix": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [[0, 0]],
                      "o": [[0, 0]],
                      "v": [[127.622, -89.51]],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0, 0.811764705882, 0.854901960784, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 2",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 94.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 97.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [100.318, -64.703]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 99.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [135.983, -130.087]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 111.001093073675,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-68.85, 104.213]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.276, -141.276]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0, 0.811764705882, 0.854901960784, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 94.001,
                    "s": [0]
                  },
                  { "t": 111.001093073675, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 3,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 92.0009059709738,
          "op": 111.001093073675,
          "st": 55.0005416130821,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 22,
          "ty": 4,
          "nm": "Shape Layer 34",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -90, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 92.001,
                  "s": [231.951, 288.125, 0],
                  "to": [-10.167, -1.167, 0],
                  "ti": [10.167, 1.167, 0]
                },
                { "t": 96.0009453610161, "s": [170.951, 281.125, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [96.416, -94.09, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 92.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [104.164, -184.283]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 109.001073378654,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [106.262, -193.724]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0, 0.811764705882, 0.854901960784, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 95.001,
                    "s": [0]
                  },
                  { "t": 109.001073378654, "s": [100] }
                ],
                "ix": 1
              },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 92.001,
                    "s": [23]
                  },
                  { "t": 101.000994598569, "s": [100] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 92.0009059709738,
          "op": 109.001073378654,
          "st": 53.000521918061,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 23,
          "ty": 4,
          "nm": "Shape Layer 33",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -27, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 92.001,
                  "s": [332, 214.5, 0],
                  "to": [-2.167, -5.5, 0],
                  "ti": [2.167, 5.5, 0]
                },
                { "t": 96.0009453610161, "s": [319, 181.5, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [-143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 93.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 96.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [99.836, -66.419]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 98.528,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [132.796, -135.728]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 110.001083226164,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-60.508, 79.667]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.148, -139.708]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0, 0.811764705882, 0.854901960784, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 93.001,
                    "s": [0]
                  },
                  { "t": 110.001083226164, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 92.0009059709738,
          "op": 110.001083226164,
          "st": 54.0005317655716,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 24,
          "ty": 4,
          "nm": "Shape Layer 32",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -90, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 92.001,
                  "s": [266, 319.5, 0],
                  "to": [-4.167, -2.333, 0],
                  "ti": [4.167, 2.333, 0]
                },
                { "t": 96.0009453610161, "s": [241, 305.5, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [[0, 0]],
                      "o": [[0, 0]],
                      "v": [[127.622, -89.51]],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0, 0.811764705882, 0.854901960784, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 2",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 93.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 96.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [100.318, -64.703]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 98.001,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [135.983, -130.087]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 110.001083226164,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-68.85, 104.213]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.276, -141.276]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0, 0.811764705882, 0.854901960784, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 93.001,
                    "s": [0]
                  },
                  { "t": 110.001083226164, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 3,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 92.0009059709738,
          "op": 110.001083226164,
          "st": 54.0005317655716,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 25,
          "ty": 4,
          "nm": "Shape Layer 55",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 50, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 35,
                  "s": [341.8, 260.9, 0],
                  "to": [-0.25, -5.083, 0],
                  "ti": [0.25, 5.083, 0]
                },
                { "t": 39.0003840529128, "s": [340.3, 230.4, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [-128.7, 145.978, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 36,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 39,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [99.836, -66.419]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 42,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-61.96, 87.17]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [102.098, -98.71]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 53.000521918061,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-60.508, 79.667]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [42.636, -85.529],
                              [113.078, -119.097]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.843137254902, 0.992156862745, 0.549019607843, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 36,
                    "s": [0]
                  },
                  { "t": 53.000521918061, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 37.0003643578916,
          "op": 53.000521918061,
          "st": -3.00002954253175,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 26,
          "ty": 4,
          "nm": "Shape Layer 54",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -13, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 35,
                  "s": [241, 221.3, 0],
                  "to": [-0.25, -5.083, 0],
                  "ti": [0.25, 5.083, 0]
                },
                { "t": 39.0003840529128, "s": [239.5, 190.8, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [128.7, 128.7, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [[0, 0]],
                      "o": [[0, 0]],
                      "v": [[127.622, -89.51]],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.843137254902, 0.992156862745, 0.549019607843, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 2",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 36,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 39,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [89.196, -75.245]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 42,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-72.97, 83.392]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [114.839, -100.679]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 53.000521918061,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-54.394, 83.576]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [51.199, -76.956],
                              [135.11, -110.236]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.843137254902, 0.992156862745, 0.549019607843, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 36,
                    "s": [0]
                  },
                  { "t": 53.000521918061, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 3,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 37.0003643578916,
          "op": 53.000521918061,
          "st": -3.00002954253175,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 27,
          "ty": 4,
          "nm": "Shape Layer 53",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -107, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 35,
                  "s": [223.125, 206.549, 0],
                  "to": [-11.667, 1.5, 0],
                  "ti": [11.667, -1.5, 0]
                },
                { "t": 39.0003840529128, "s": [153.125, 215.549, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [96.416, -94.09, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 35,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [104.164, -184.283]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 52.0005120705504,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [106.262, -193.724]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.843137254902, 0.992156862745, 0.549019607843, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 38,
                    "s": [0]
                  },
                  { "t": 52.0005120705504, "s": [100] }
                ],
                "ix": 1
              },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 35,
                    "s": [23]
                  },
                  { "t": 44.0004332904657, "s": [100] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 37.0003643578916,
          "op": 51.0005022230398,
          "st": -4.00003939004234,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 28,
          "ty": 4,
          "nm": "Shape Layer 52",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -26, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 35,
                  "s": [336.5, 179.5, 0],
                  "to": [-5.417, -7.25, 0],
                  "ti": [5.417, 7.25, 0]
                },
                { "t": 39.0003840529128, "s": [304, 136, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [-143, 162.197, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 36,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 39,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [99.836, -66.419]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 42,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [132.796, -135.728]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 53.000521918061,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-60.508, 79.667]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.148, -139.708]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.843137254902, 0.992156862745, 0.549019607843, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 36,
                    "s": [0]
                  },
                  { "t": 53.000521918061, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 37.0003643578916,
          "op": 52.0005120705504,
          "st": -3.00002954253175,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 29,
          "ty": 4,
          "nm": "Shape Layer 51",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -89, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 35,
                  "s": [261.5, 274.5, 0],
                  "to": [-7.25, -4.583, 0],
                  "ti": [7.25, 4.583, 0]
                },
                { "t": 39.0003840529128, "s": [218, 247, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [[0, 0]],
                      "o": [[0, 0]],
                      "v": [[127.622, -89.51]],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.843137254902, 0.992156862745, 0.549019607843, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 2",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 36,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 39,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [100.318, -64.703]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 42,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [135.983, -130.087]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 53.000521918061,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-68.85, 104.213]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.276, -141.276]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.843137254902, 0.992156862745, 0.549019607843, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 36,
                    "s": [0]
                  },
                  { "t": 53.000521918061, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 3,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 37.0003643578916,
          "op": 52.0005120705504,
          "st": -3.00002954253175,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 30,
          "ty": 4,
          "nm": "Shape Layer 50",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 110, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 35,
                  "s": [377.125, 199.049, 0],
                  "to": [1.167, 0.667, 0],
                  "ti": [-1.167, -0.667, 0]
                },
                { "t": 39.0003840529128, "s": [384.125, 203.049, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [96.416, -94.09, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 35,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [98.919, -132.099]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 52.0005120705504,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [98.548, -141.028]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.843137254902, 0.992156862745, 0.549019607843, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 38,
                    "s": [0]
                  },
                  { "t": 52.0005120705504, "s": [100] }
                ],
                "ix": 1
              },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 35,
                    "s": [23]
                  },
                  { "t": 44.0004332904657, "s": [100] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 37.0003643578916,
          "op": 52.0005120705504,
          "st": -4.00003939004234,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 31,
          "ty": 4,
          "nm": "Shape Layer 49",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -22, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 35,
                  "s": [267.125, 156.049, 0],
                  "to": [-3.5, -9.167, 0],
                  "ti": [3.5, 9.167, 0]
                },
                { "t": 39.0003840529128, "s": [246.125, 101.049, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [96.416, -94.09, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 35,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [98.919, -132.099]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 52.0005120705504,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [98.548, -141.028]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.843137254902, 0.992156862745, 0.549019607843, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 38,
                    "s": [0]
                  },
                  { "t": 52.0005120705504, "s": [100] }
                ],
                "ix": 1
              },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 35,
                    "s": [23]
                  },
                  { "t": 44.0004332904657, "s": [100] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 37.0003643578916,
          "op": 52.0005120705504,
          "st": -4.00003939004234,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 32,
          "ty": 4,
          "nm": "Shape Layer 48",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -191, "ix": 10 },
            "p": { "a": 0, "k": [234.5, 258, 0], "ix": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [-143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 37,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 40,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [99.836, -66.419]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 42.527,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [132.796, -135.728]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 54.0005317655716,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-60.508, 79.667]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.148, -139.708]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.843137254902, 0.992156862745, 0.549019607843, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 37,
                    "s": [0]
                  },
                  { "t": 54.0005317655716, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 37.0003643578916,
          "op": 53.000521918061,
          "st": -2.00001969502117,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 33,
          "ty": 4,
          "nm": "Shape Layer 47",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -254, "ix": 10 },
            "p": { "a": 0, "k": [338.5, 165, 0], "ix": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [[0, 0]],
                      "o": [[0, 0]],
                      "v": [[127.622, -89.51]],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.843137254902, 0.992156862745, 0.549019607843, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 2",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 37,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 40,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [100.318, -64.703]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 42,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [135.983, -130.087]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 54.0005317655716,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-68.85, 104.213]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.276, -141.276]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.843137254902, 0.992156862745, 0.549019607843, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 37,
                    "s": [0]
                  },
                  { "t": 54.0005317655716, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 3,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 37.0003643578916,
          "op": 53.000521918061,
          "st": -2.00001969502117,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 34,
          "ty": 4,
          "nm": "Shape Layer 46",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -180, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 35,
                  "s": [288.125, 248.049, 0],
                  "to": [-1.167, 10.167, 0],
                  "ti": [1.167, -10.167, 0]
                },
                { "t": 39.0003840529128, "s": [281.125, 309.049, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [96.416, -94.09, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 35,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [104.164, -184.283]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 52.0005120705504,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [106.262, -193.724]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.843137254902, 0.992156862745, 0.549019607843, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 38,
                    "s": [0]
                  },
                  { "t": 52.0005120705504, "s": [100] }
                ],
                "ix": 1
              },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 35,
                    "s": [23]
                  },
                  { "t": 44.0004332904657, "s": [100] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 37.0003643578916,
          "op": 51.0005022230398,
          "st": -4.00003939004234,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 35,
          "ty": 4,
          "nm": "Shape Layer 45",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -117, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 35,
                  "s": [214.5, 148, 0],
                  "to": [-5.5, 2.167, 0],
                  "ti": [5.5, -2.167, 0]
                },
                { "t": 39.0003840529128, "s": [181.5, 161, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [-143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 36,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 39,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [99.836, -66.419]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 41.527,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [132.796, -135.728]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 53.000521918061,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-60.508, 79.667]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.148, -139.708]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.843137254902, 0.992156862745, 0.549019607843, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 36,
                    "s": [0]
                  },
                  { "t": 53.000521918061, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 37.0003643578916,
          "op": 52.0005120705504,
          "st": -3.00002954253175,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 36,
          "ty": 4,
          "nm": "Shape Layer 44",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -180, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 35,
                  "s": [319.5, 214, 0],
                  "to": [-2.333, 4.167, 0],
                  "ti": [2.333, -4.167, 0]
                },
                { "t": 39.0003840529128, "s": [305.5, 239, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [[0, 0]],
                      "o": [[0, 0]],
                      "v": [[127.622, -89.51]],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.843137254902, 0.992156862745, 0.549019607843, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 2",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 36,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 39,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [100.318, -64.703]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 41,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [135.983, -130.087]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 53.000521918061,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-68.85, 104.213]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.276, -141.276]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [0.843137254902, 0.992156862745, 0.549019607843, 1],
                    "ix": 3
                  },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 36,
                    "s": [0]
                  },
                  { "t": 53.000521918061, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 3,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 37.0003643578916,
          "op": 52.0005120705504,
          "st": -3.00002954253175,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 37,
          "ty": 4,
          "nm": "Shape Layer 79",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 140, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 6,
                  "s": [219.1, 341.8, 0],
                  "to": [5.083, -0.25, 0],
                  "ti": [-5.083, 0.25, 0]
                },
                { "t": 10.0000984751058, "s": [249.6, 340.3, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [-128.7, 145.978, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 7,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 10,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [99.836, -66.419]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 13,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-61.96, 87.17]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [102.098, -98.71]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 24.000236340254,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-60.508, 79.667]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [42.636, -85.529],
                              [113.078, -119.097]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 7,
                    "s": [0]
                  },
                  { "t": 24.000236340254, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 8.00007878008468,
          "op": 24.000236340254,
          "st": -32.0003151203387,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 38,
          "ty": 4,
          "nm": "Shape Layer 78",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 77, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 6,
                  "s": [258.7, 241, 0],
                  "to": [5.083, -0.25, 0],
                  "ti": [-5.083, 0.25, 0]
                },
                { "t": 10.0000984751058, "s": [289.2, 239.5, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [128.7, 128.7, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [[0, 0]],
                      "o": [[0, 0]],
                      "v": [[127.622, -89.51]],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 2",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 7,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 10,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [89.196, -75.245]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 13,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-72.97, 83.392]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [114.839, -100.679]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 24.000236340254,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-54.394, 83.576]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [51.199, -76.956],
                              [135.11, -110.236]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 7,
                    "s": [0]
                  },
                  { "t": 24.000236340254, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 3,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 8.00007878008468,
          "op": 24.000236340254,
          "st": -32.0003151203387,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 39,
          "ty": 4,
          "nm": "Shape Layer 77",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -17, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 6,
                  "s": [273.451, 223.125, 0],
                  "to": [-1.5, -11.667, 0],
                  "ti": [1.5, 11.667, 0]
                },
                { "t": 10.0000984751058, "s": [264.451, 153.125, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [96.416, -94.09, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 6,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [104.164, -184.283]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 23.0002264927434,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [106.262, -193.724]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 9,
                    "s": [0]
                  },
                  { "t": 23.0002264927434, "s": [100] }
                ],
                "ix": 1
              },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 6,
                    "s": [23]
                  },
                  { "t": 15.0001477126588, "s": [100] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 8.00007878008468,
          "op": 23.0002264927434,
          "st": -33.0003249678493,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 40,
          "ty": 4,
          "nm": "Shape Layer 76",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 64, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 6,
                  "s": [300.5, 336.5, 0],
                  "to": [7.25, -5.417, 0],
                  "ti": [-7.25, 5.417, 0]
                },
                { "t": 10.0000984751058, "s": [344, 304, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [-143, 162.197, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 7,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 10,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [99.836, -66.419]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 13,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [132.796, -135.728]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 24.000236340254,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-60.508, 79.667]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.148, -139.708]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 7,
                    "s": [0]
                  },
                  { "t": 24.000236340254, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 8.00007878008468,
          "op": 24.000236340254,
          "st": -32.0003151203387,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 41,
          "ty": 4,
          "nm": "Shape Layer 75",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 1, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 6,
                  "s": [205.5, 261.5, 0],
                  "to": [4.583, -7.25, 0],
                  "ti": [-4.583, 7.25, 0]
                },
                { "t": 10.0000984751058, "s": [233, 218, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [[0, 0]],
                      "o": [[0, 0]],
                      "v": [[127.622, -89.51]],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 2",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 7,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 10,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [100.318, -64.703]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 13,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [135.983, -130.087]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 24.000236340254,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-68.85, 104.213]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.276, -141.276]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 7,
                    "s": [0]
                  },
                  { "t": 24.000236340254, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 3,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 8.00007878008468,
          "op": 24.000236340254,
          "st": -32.0003151203387,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 42,
          "ty": 4,
          "nm": "Shape Layer 74",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 200, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 6,
                  "s": [280.951, 377.125, 0],
                  "to": [-0.667, 1.167, 0],
                  "ti": [0.667, -1.167, 0]
                },
                { "t": 10.0000984751058, "s": [276.951, 384.125, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [96.416, -94.09, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 6,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [98.919, -132.099]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 23.0002264927434,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [98.548, -141.028]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 9,
                    "s": [0]
                  },
                  { "t": 23.0002264927434, "s": [100] }
                ],
                "ix": 1
              },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 6,
                    "s": [23]
                  },
                  { "t": 15.0001477126588, "s": [100] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 8.00007878008468,
          "op": 20.0001969502117,
          "st": -33.0003249678493,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 43,
          "ty": 4,
          "nm": "Shape Layer 73",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 68, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 6,
                  "s": [323.951, 267.125, 0],
                  "to": [9.167, -3.5, 0],
                  "ti": [-9.167, 3.5, 0]
                },
                { "t": 10.0000984751058, "s": [378.951, 246.125, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [96.416, -94.09, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 6,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [98.919, -132.099]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 23.0002264927434,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [98.548, -141.028]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 9,
                    "s": [0]
                  },
                  { "t": 23.0002264927434, "s": [100] }
                ],
                "ix": 1
              },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 6,
                    "s": [23]
                  },
                  { "t": 15.0001477126588, "s": [100] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 8.00007878008468,
          "op": 23.0002264927434,
          "st": -33.0003249678493,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 44,
          "ty": 4,
          "nm": "Shape Layer 72",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -101, "ix": 10 },
            "p": { "a": 0, "k": [222, 234.5, 0], "ix": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [-143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 8,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 11,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [99.836, -66.419]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 13.527,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [132.796, -135.728]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 25.0002461877646,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-60.508, 79.667]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.148, -139.708]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 8,
                    "s": [0]
                  },
                  { "t": 25.0002461877646, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 8.00007878008468,
          "op": 25.0002461877646,
          "st": -31.0003052728281,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 45,
          "ty": 4,
          "nm": "Shape Layer 71",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -164, "ix": 10 },
            "p": { "a": 0, "k": [315, 338.5, 0], "ix": 2 },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [[0, 0]],
                      "o": [[0, 0]],
                      "v": [[127.622, -89.51]],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 2",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 8,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 11,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [100.318, -64.703]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 13,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [135.983, -130.087]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 25.0002461877646,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-68.85, 104.213]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.276, -141.276]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 8,
                    "s": [0]
                  },
                  { "t": 25.0002461877646, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 3,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 8.00007878008468,
          "op": 25.0002461877646,
          "st": -31.0003052728281,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 46,
          "ty": 4,
          "nm": "Shape Layer 70",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -90, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 6,
                  "s": [231.951, 288.125, 0],
                  "to": [-10.167, -1.167, 0],
                  "ti": [10.167, 1.167, 0]
                },
                { "t": 10.0000984751058, "s": [170.951, 281.125, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [96.416, -94.09, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 6,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [104.164, -184.283]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 23.0002264927434,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [0, 0]
                            ],
                            "o": [
                              [-0.441, -0.853],
                              [0, 0]
                            ],
                            "v": [
                              [95.545, -82.364],
                              [106.262, -193.724]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 9,
                    "s": [0]
                  },
                  { "t": 23.0002264927434, "s": [100] }
                ],
                "ix": 1
              },
              "e": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 6,
                    "s": [23]
                  },
                  { "t": 15.0001477126588, "s": [100] }
                ],
                "ix": 2
              },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 8.00007878008468,
          "op": 23.0002264927434,
          "st": -33.0003249678493,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 47,
          "ty": 4,
          "nm": "Shape Layer 69",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -27, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 6,
                  "s": [332, 214.5, 0],
                  "to": [-2.167, -5.5, 0],
                  "ti": [2.167, 5.5, 0]
                },
                { "t": 10.0000984751058, "s": [319, 181.5, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [-143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 7,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 10,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [99.836, -66.419]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 12.527,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [132.796, -135.728]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 24.000236340254,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-60.508, 79.667]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.148, -139.708]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 7,
                    "s": [0]
                  },
                  { "t": 24.000236340254, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 2,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 8.00007878008468,
          "op": 24.000236340254,
          "st": -32.0003151203387,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 48,
          "ty": 4,
          "nm": "Shape Layer 68",
          "parent": 49,
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": -90, "ix": 10 },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": { "x": 0.667, "y": 1 },
                  "o": { "x": 0.333, "y": 0 },
                  "t": 6,
                  "s": [266, 319.5, 0],
                  "to": [-4.167, -2.333, 0],
                  "ti": [4.167, 2.333, 0]
                },
                { "t": 10.0000984751058, "s": [241, 305.5, 0] }
              ],
              "ix": 2
            },
            "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
            "s": { "a": 0, "k": [143, 143, 100], "ix": 6 }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [[0, 0]],
                      "o": [[0, 0]],
                      "v": [[127.622, -89.51]],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 2",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": { "x": 0.833, "y": 0.833 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 7,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-44.374, 35.682]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [41, -53.692],
                              [77.941, -44.073]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.167, "y": 0.167 },
                        "t": 10,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-77.591, 74.843]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [46.245, -83.413],
                              [100.318, -64.703]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": { "x": 0.667, "y": 1 },
                        "o": { "x": 0.333, "y": 0 },
                        "t": 12,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-103.115, 136.731]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [135.983, -130.087]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 24.000236340254,
                        "s": [
                          {
                            "i": [
                              [0, 0],
                              [-68.85, 104.213]
                            ],
                            "o": [
                              [0, 0],
                              [-24.444, 35.682]
                            ],
                            "v": [
                              [54.636, -95.301],
                              [142.276, -141.276]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                  "o": { "a": 0, "k": 100, "ix": 4 },
                  "w": { "a": 0, "k": 6, "ix": 5 },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [0, 0], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Shape 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tm",
              "s": {
                "a": 1,
                "k": [
                  {
                    "i": { "x": [0.667], "y": [1] },
                    "o": { "x": [0.333], "y": [0] },
                    "t": 7,
                    "s": [0]
                  },
                  { "t": 24.000236340254, "s": [100] }
                ],
                "ix": 1
              },
              "e": { "a": 0, "k": 100, "ix": 2 },
              "o": { "a": 0, "k": 0, "ix": 3 },
              "m": 1,
              "ix": 3,
              "nm": "Trim Paths 1",
              "mn": "ADBE Vector Filter - Trim",
              "hd": false
            }
          ],
          "ip": 8.00007878008468,
          "op": 24.000236340254,
          "st": -32.0003151203387,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 49,
          "ty": 3,
          "nm": "Cyan Solid 1",
          "sr": 1,
          "ks": {
            "o": { "a": 0, "k": 100, "ix": 11 },
            "r": { "a": 0, "k": 180, "ix": 10 },
            "p": { "a": 0, "k": [100, 100, 0], "ix": 2 },
            "a": { "a": 0, "k": [240, 240, 0], "ix": 1 },
            "s": { "a": 0, "k": [41.719, 41.719, 100], "ix": 6 }
          },
          "ao": 0,
          "ip": 0,
          "op": 357.003515561279,
          "st": 0,
          "bm": 0
        }
      ]
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 0,
      "nm": "SMASH_Left",
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [101, 99.875, 0], "ix": 2 },
        "a": { "a": 0, "k": [100, 100, 0], "ix": 1 },
        "s": { "a": 0, "k": [91, 91, 100], "ix": 6 }
      },
      "ao": 0,
      "w": 200,
      "h": 200,
      "ip": 60.0005908506351,
      "op": 113.001112768696,
      "st": 60.0005908506351,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 0,
      "nm": "SMASH_Left",
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [101, 99.875, 0], "ix": 2 },
        "a": { "a": 0, "k": [100, 100, 0], "ix": 1 },
        "s": { "a": 0, "k": [91, 91, 100], "ix": 6 }
      },
      "ao": 0,
      "w": 200,
      "h": 200,
      "ip": 0,
      "op": 67.0006597832092,
      "st": -53.000521918061,
      "bm": 0
    }
  ],
  "markers": []
}
